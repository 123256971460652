<template>
    <div class="home">
        <Banner typeVal="1" />

        <!-- 数据展示 -->
        <div class="data-w-box">
            <div class="data-count-box">
                <div class="count-item-box">
                    <div class="count-left">
                        <img src="../assets/d-icon01.png" alt="" />
                    </div>
                    <div class="count-right">
                        <div class="count-num" v-if="statisticsCount[0]" v-cloak >{{statisticsCount[0]}}</div>
                        <div class="count-title">订单总数</div>
                    </div>
                </div>
                <div class="count-item-box">
                    <div class="count-left">
                        <img src="../assets/d-icon02.png" alt="" />
                    </div>
                    <div class="count-right">
                        <div class="count-num" style="color: #7a7dfc" v-if="statisticsCount[2]" v-cloak >
                            <span>¥</span>{{priceUnitConvert(statisticsCount[2])||''}}
                        </div>
                        <div class="count-title">支付总额</div>
                    </div>
                </div>
                <div class="count-item-box">
                    <div class="count-left">
                        <img src="../assets/d-icon04.png" alt="" />
                    </div>
                    <div class="count-right">
                        <div class="count-num" style="color: #54d9ff" v-if="statisticsCount[3]" v-cloak >
                            <span>¥</span>{{priceUnitConvert(statisticsCount[3]||'')}}
                        </div>
                        <div class="count-title">融资总额</div>
                    </div>
                </div>
                <div class="count-item-box">
                    <div class="count-left">
                        <img src="../assets/d-icon05.png" alt="" />
                    </div>
                    <div class="count-right">
                        <div class="count-num" style="color: #FFA54D" v-if="statisticsCount[4]" v-cloak >
                            <span>¥</span>{{priceUnitConvert(statisticsCount[4]||'')}}
                        </div>
                        <div class="count-title">融资已付款总额</div>
                    </div>
                </div>
                <div class="count-item-box">
                    <div class="count-left">
                        <img src="../assets/d-icon03.png" alt="" />
                    </div>
                    <div class="count-right">
                        <div class="count-num" style="color: #54a1ff" v-if="statisticsCount[1]" v-cloak >
                            {{statisticsCount[1]}}
                        </div>
                        <div class="count-title">供应商总数</div>
                    </div>
                </div>
            </div>
            <div class="data-chart-box">
                <div class="chart-left-box">
                    <div
                        id="orderChart01"
                    ></div>
                    <div
                        id="orderChart02"
                    ></div>
                    <div class="order-change">
                        <el-radio-group v-model="orderChangeVal" @change="orderChange" size="small">
                            <el-radio-button label="1">日</el-radio-button>
                            <el-radio-button label="2">月</el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
                <div class="chart-right-box" v-if="showOrder" >
                    <div class="table-box">
                        <div class="table-header-box">
                            <div class="header-item">序号</div>
                            <div class="header-item">公司名称</div>
                            <div class="header-item">产品名称</div>
                            <div class="header-item">销售数量</div>
                            <div class="header-item">交易金额</div>
                        </div>
                        <div class="tr-w-box" v-if="showOrder" >
                            <div
                                class="swiper mySwiper3"
                                style="width: 100%; height: 26.5em"
                                v-if="showOrder"
                            >
                                <div class="swiper-wrapper" v-if="showOrder">
                                    <div
                                        class="tr-item-box swiper-slide"
                                        v-for="(item, index) in tableList"
                                        :key="index"
                                    >
                                        <div class="td-item">{{ index+1 }}</div>
                                        <div class="td-item">{{item.gsmc}}</div>
                                        <div class="td-item">{{item.wlmc}}</div>
                                        <div
                                            class="td-item"
                                            style="color: #0073fe"
                                        >
                                            {{item.xssl}}
                                        </div>
                                        <div
                                            class="td-item"
                                            style="color: #0073fe"
                                        >
                                            ¥{{item.xsje}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-box">
            <!-- 金融资讯 -->
            <!-- 类型标题栏 -->
            <div class="type-title-item-box">
                <div class="type-title-left">
                    <div class="type-title">金融资讯</div>
                    <div class="type-title-item-line"></div>
                </div>
                <div class="type-title-right" @click="routerpush('/news')">
                    查看更多>
                </div>
            </div>
            <div class="content-box" style="justify-content: space-between">
                <div
                    class="content-item-box"
                    v-for="(item, index) in information_list"
                    :key="index"
                >
                    <el-image
                        :src="item.img"
                        style="width: 100%; height: 37.5em"
                        fit="cover"
                    ></el-image>
                    <div class="intellectual-box">
                        <div class="title">{{ item.title }}</div>
                        <div class="intellectual-des-box">
                            <div class="intellectual">
                                {{ item.intro }}
                            </div>
                            <div
                                class="visit-btn"
                                @click="financeDetailsPage(item)"
                            >
                                访问
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 金融平台 -->
            <div class="type-title-item-box">
                <div class="type-title-left">
                    <div class="type-title">金融平台</div>
                    <div class="type-title-item-line"></div>
                </div>
                <div class="type-title-right"></div>
            </div>
            <div class="content-box">
                <el-carousel
                    :interval="3000"
                    arrow="always"
                    height="33em"
                    style="width: 100%"
                    indicator-position="none"
                    class="plmSwiper"
                >
                    <el-carousel-item
                        v-for="(sitem, sindex) in platform_list"
                        :key="sindex"
                    >
                        <div class="ct-item-box">
                            <div class="content-left-box">
                                <div>
                                    <span
                                        >{{ sindex < 9 ? "0" : ""
                                        }}{{ sindex + 1 }}</span
                                    >
                                    <div>{{ sitem.title }}</div>
                                </div>
                                <p>
                                    {{ sitem.intro }}
                                </p>
                            </div>
                            <div class="content-right-box">
                                <img :src="sitem.img" alt="" />
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>

            <!-- 金融产品 -->
            <div class="type-title-item-box">
                <div class="type-title-left">
                    <div class="type-title">金融产品</div>
                    <div class="type-title-item-line"></div>
                </div>
                <div class="type-title-right" @click="routerpush('/product')">
                    查看更多>
                </div>
            </div>
            <div class="content-box last-box">
                <div
                    class="product-item-box"
                    v-for="(pitem, pindex) in product_list"
                    :key="pindex"
                >
                    <el-image
                        :src="pitem.img"
                        style="width: 100%; height: 20em"
                        fit="cover"
                    ></el-image>
                    <div class="product-des-box">
                        <div class="product-title">{{ pitem.name }}</div>
                        <div class="product-des">
                            {{ pitem.intro }}
                        </div>
                        <div class="price"><span>¥</span>{{ pitem.price }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import * as echarts from "echarts";
import HelloWorld from "@/components/HelloWorld.vue";
import Banner from "@/components/Banner.vue";
import SwiperDemo from "@/components/SwiperDemo.vue";
import "swiper/css/swiper.css";
import Swiper from "swiper";
import { getRequest, postRequest, phoneRegex } from "../utils/request.js";
import * as dd from "dingtalk-jsapi";

export default {
    name: "HomeView",
    components: {
        HelloWorld,
        Banner,
        SwiperDemo,
    },
    data() {
        return {
            tableList: [1,2,3,4,5,6,7,8,9,10],
            information_list: [], //金融资讯
            platform_list: [], //金融平台
            product_list: [], //金融产品
            height: "",
            statisticsCount:[],
            showOrder:false,
            orderChangeVal:2,//1--日  2--月
        };
    },

    mounted() {
        var _this = this;
        // 先判断是否是在钉钉中运行此应用
        if (dd.env.platform != "notInDingTalk") {
            dd.ready(() => {
                dd.runtime.permission
                    .requestAuthCode({
                        corpId: "ding576ccf0410654608f2c783f7214b6d69",
                    })
                    .then((result) => {
                        var code = result.code; // 通过该免登授权码可以获取用户身份
                        _this.dingdingLogin(code);
                        _this.getAuthData();
                    })
                    .catch((err) => {
                        console.log(err);
                        alert(err);
                    });
            });
        } else {
            console.warn("请在钉钉中访问本应用!");
            // _this.getAuthData();
        }
        window.addEventListener("resize", _this.handleResize); // 添加resize 事件监听器
        _this.getOrderMonthlyTotal();
        _this.getDataa();
        _this.getStatisticsTotal();
        _this.getSellTotal();
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize); // 移除resize 事件监听器
    },
    methods: {
        orderChange(e){
            var _this = this;
            console.log(e)
            if(e==1){
                _this.getOrderDayTotal();
            }
            if(e==2){
                _this.getOrderMonthlyTotal();
            }
        },
        routerpush(to, query) {
            this.$router.push({ path: to, query: query ? query : "" });
        },
        financeDetailsPage(info) {
            this.$router.push({
                path: "/financeDetails",
                query: { id: info.article_id },
            });
        },
        swiperLoad() {
            const swiper01 = new Swiper(".mySwiper3", {
                slidesPerView: 7,
                direction: "vertical",
                // autoplay: true,
                loop: true,
                autoplay: {
                    delay: 1000,
                },
            });
        },

        initChart(timeArr,orderArr,priceArr) {
            var _this = this;
            // console.log(timeArr)
            // console.log(orderArr)
            // console.log(priceArr)
            _this.chart01 = echarts.init(
                document.getElementById("orderChart01")
            );
            _this.chart02 = echarts.init(
                document.getElementById("orderChart02")
            );
            const option01 = {
                color: "#0073FE",
                title: {
                    text: "订单总数",
                    textStyle: {
                        color: "#0073FE",
                        fontSize: 16,
                        fontWeight: "400",
                    },
                },
                grid: {
                    top: "25%",
                    left: "15%",
                    right: "4%",
                    bottom: "20%",
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: timeArr,
                    show: true,
                },
                yAxis: {
                    type: "value",
                },
                tooltip: {
                    trigger: "axis",
                    textStyle: {
                        align: "left",
                    },
                },
                series: [
                    {
                        data: orderArr,
                        type: "line",
                        showSymbol: false,
                        center: ["30%", "30%"],
                        areaStyle: {
                            opacity: 0.8,
                            color: new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 0,
                                        color: "#0073FE",
                                    },
                                    {
                                        offset: 1,
                                        color: "#ffffff",
                                    },
                                ]
                            ),
                        },
                    },
                ],
            };
            const option02 = {
                color: "#0073FE",
                title: {
                    text: "采购金额",
                    textStyle: {
                        color: "#0073FE",
                        fontSize: 16,
                        fontWeight: "400",
                    },
                },
                grid: {
                    top: "25%",
                    left: "15%",
                    right: "4%",
                    bottom: "20%",
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: timeArr,
                    show: true,
                },
                yAxis: {
                    type: "value",
                    axisLabel: {
                        // 使用 formatter 格式化标签
                        formatter: function(number) {
                            // 对于大于 1000 的数值使用千位分隔符
                            return _this.numUnitConvert(number)
                        }
                    }
                },
                tooltip: {
                    trigger: "axis",
                    textStyle: {
                        align: "left",
                    },
                },
                series: [
                    {
                        data: priceArr,
                        type: "line",
                        showSymbol: false,
                        center: ["10%", "10%"],
                        areaStyle: {
                            opacity: 0.8,
                            color: new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 0,
                                        color: "#0073FE",
                                    },
                                    {
                                        offset: 1,
                                        color: "#ffffff",
                                    },
                                ]
                            ),
                        },
                    },
                ],
            };

            _this.chart01.setOption(option01);
            _this.chart02.setOption(option02);
        },
        handleResize() {
            this.chart01.resize();
            this.chart02.resize();
        },
        getDataa() {
            let _this = this;
            let params = {};
            let url = "/api/index/getHomeInfo";
            postRequest(url, params, function (e) {
                if (e.status == 200) {
                    _this.information_list = e.data.information_list; //金融资讯
                    _this.platform_list = e.data.platform_list; //金融平台
                    _this.product_list = e.data.product_list; //金融产品
                }
            });
        },
        dingdingLogin(code) {
            let _this = this;
            let params = {
                code: code,
            };
            let url = "/api/Login/login";
            postRequest(url, params, function (e) {
                if (e.status == 200) {
                    _this.loginSuccess(
                        e.data.token,
                        JSON.stringify(e.data.user)
                    );
                }
            });
        },
        loginSuccess(token, userInfo) {
            let _this = this;
            var token = token;
            var user = userInfo;
            var company = userInfo?JSON.stringify(JSON.parse(userInfo).dept_list[0]):'';
            _this.$store.commit("LOGIN_IN", token);
            _this.$store.commit("UPDATA_USER", user);
            _this.$store.commit("UPDATA_COMPANY", company);
            let timeout = setTimeout(function () {
                clearTimeout(timeout);
                _this.routerpush("/");
            }, 500);
        },
        getAuthData(){
            let _this = this;
            var mid = "";
            if(_this.$store.state.UserInfo&&_this.$store.state.UserInfo!="undefined"&&_this.$store.state.UserInfo!="null"){
                var userinfo = JSON.parse(_this.$store.state.UserInfo);
                mid = userinfo.mid;
            }
            let params = {
                "mid":mid,
            };
            let url = "/api/index/getHomeMenuAuth";
            postRequest(url, params, function (e) {
                // console.log("权限222222")
                // console.log(e)
                if (e.status == 200) {
                    _this.$store.commit("UPDATA_AUTH", JSON.stringify(e.data));
                }
            });
        },
        // 统计
        getStatisticsTotal(){
            let _this = this;
            let params = {};
            let url = "/api/DataDetail/indexPageTotal";
            postRequest(url, params, function (e) {
                if (e.status == 200) {
                    var a = e.data.ddTotal||0;
                    var b = e.data.gysTotal||0;
                    var c = e.data.zfTotal||0;
                    var d = e.data.rzTotal||0;
                    var e = e.data.rzYfkTotal||0;
                    _this.statisticsCount = [a,b,c,d,e]
                }
            });
        },
        getSellTotal(){
            let _this = this;
            let params = {};
            let url = "/api/DataDetail/salesDetail";
            postRequest(url, params, function (e) {
                console.log("销售统计")
                console.log(e)
                if (e.status == 200) {
                    _this.tableList = e.data.data;
                    _this.showOrder = true;
                    setTimeout(function () {
                        _this.swiperLoad();
                    }, 0)
                }
            });
        
        },

        numUnitConvert(number){
            if (!number && number != 0) return number;
            var str_num
            if (number >= 1E3 && number < 1E4) {
                str_num = number / 1E3
                return str_num + '千'
            } else if (number >= 1E4 && number < 1E6) {
                str_num = number / 1E4
                return str_num + '万'
            } else if (number >= 1E6 && number < 1E7) {
                str_num = number / 1E6
                return str_num + '百万'
            } else if (number >= 1E7 && number < 1E8) {
                str_num = number / 1E7
                return str_num + '千万'
            } else if (number >= 1E8 && number < 1E10) {
                str_num = number / 1E8
                return str_num + '亿'
            } else if (number >= 1E10 && number < 1E11) {
                str_num = number / 1E10
                return str_num + '百亿'
            } else if (number >= 1E11 && number < 1E12) {
                str_num = number / 1E11
                return str_num + '千亿'
            } else if (number >= 1E12) {
                str_num = number / 1E12
                return str_num + '万亿'
            } else { //一千以下
                return number
            }
        },

        // 金额单位转换
        priceUnitConvert(price){
            var newPrice = 0;
            var p = parseFloat(price);
            if((p>100000||p==100000)&&p<100000000){
                newPrice = (p/10000).toFixed(2)+' 万'
            }else if(p>100000000||p==100000000){
                newPrice = (p/100000000).toFixed(2)+' 亿'
            }else{
                newPrice = p
            }
            return newPrice;
        },
        // 月
        getOrderMonthlyTotal(){
            let _this = this;
            let params = {};
            let url = "/api/DataDetail/orderMonthlyTotal ";
            postRequest(url, params, function (e) {
                // console.log("销售订单金额统计")
                // console.log(e)
                if (e.status == 200) {
                    var dataArr = e.data;
                    var timeArr = [];
                    var orderArr = [];
                    var priceArr = [];
                    dataArr.forEach(function(item,index){
                        timeArr.push(item.ny)
                        orderArr.push(item.sl)
                        priceArr.push(item.je)
                    });
                    setTimeout(function(){
                        _this.initChart(timeArr,orderArr,priceArr);
                    },0)
                }
            });
        },
        
        // 日
        getOrderDayTotal(){
            let _this = this;
            let params = {};
            let url = "/api/DataDetail/orderDayTotal";
            postRequest(url, params, function (e) {
                // console.log("销售订单金额统计")
                // console.log(e)
                if (e.status == 200) {
                    var dataArr = e.data;
                    var timeArr = [];
                    var orderArr = [];
                    var priceArr = [];
                    dataArr.forEach(function(item,index){
                        timeArr.push(item.ny)
                        orderArr.push(item.sl)
                        priceArr.push(item.je)
                    });
                    setTimeout(function(){
                        _this.initChart(timeArr,orderArr,priceArr);
                    },0)
                }
            });
        },
        
        
    },
};
</script>
<style lang="less" scoped>
[v-cloak] {
    display: none;
}
.type-title-item-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 8.75em;
}
.type-title-left {
    position: relative;
    span {
        width: 31.25em;
        display: inline-block;
        font-size: 2.1em;
        font-weight: bold;
        color: #0073fe20;
        position: absolute;
        left: 1em;
        bottom: -0.4em;
        text-align: left;
        vertical-align: top;
    }
    .type-title-item-line {
        width: 100px;
        height: 4px;
        background-color: #0073fe;
        position: absolute;
        left: 0;
        bottom: 0px;
    }
}
.type-title-right {
    font-size: 1em;
    color: #0073fe;
    cursor: pointer;
}
.type-title {
    font-size: 3.1em;
    color: #333333;
    font-weight: bold;
}
.content-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 8.75em;
}
.ct-item-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.content-left-box {
    flex: 1;
    text-align: left;
    margin-right: 8.75em;
    div {
        padding-left: 0.4em;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        img {
            width: 3.875em;
            height: 3.125em;
        }
        span {
            font-size: 5em;
            font-weight: bold;
            color: rgba(0, 115, 254, 0.5);
        }
        div {
            font-size: 2.25em;
            font-weight: bold;
            padding-bottom: 0.5em;
        }
    }
    p {
        font-size: 1em;
        color: #999999;
        margin-top: 1.875em;
        line-height: 2em;
    }
}
.content-right-box {
    flex: 1;
    text-align: right;
    img {
        width: 43.75em;
        height: 31.25em;
        background-color: rgba(0, 0, 0, 0);
        box-shadow: 1.25em 1.25em 0px #0073ff20;
        margin-right: 1.25em;
        margin-bottom: 1.25em;
    }
}
.content-item-box {
    width: 27.5em;
    height: 37.5em;
    position: relative;
    img {
        width: 100%;
        height: 37.5em;
    }
}
.intellectual-box {
    width: 100%;
    height: 37.5em;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 1.875em 1.25em;
    box-sizing: border-box;
    background: linear-gradient(0deg, #000000 0%, #ffffff00 100%);
    .title {
        font-size: 1.25em;
        font-weight: bold;
        color: #ffffff;
        text-align: left;
    }
}
.intellectual-des-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.25em;
}
.intellectual {
    flex: 1;
    margin-right: 1.875em;
    font-size: 1em;
    color: #ffffff;
    text-align: left;
    line-height: 2em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}
.product-item-box {
    width: 31%;
    position: relative;
    img {
        width: 100%;
        height: 20em;
    }
}
.product-des-box {
    width: 84%;
    height: 17.5em;
    position: absolute;
    left: 8%;
    bottom: -10.625em;
    background-color: #ffffff;
    padding: 2.5em;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0.625em rgba(0, 115, 254, 0.1);
}
.product-title {
    width: 100%;
    font-size: 1.25em;
    color: #333333;
    font-weight: bold;
    text-align: center;
}
.product-des {
    flex: 1;
    width: 100%;
    line-height: 2em;
    font-size: 1em;
    color: #666666;
    margin: 0.625em 0px;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}
.price {
    width: 100%;
    text-align: center;
    font-size: 1.875em;
    font-weight: bold;
    color: #0073fe;
    margin-top: 0.625em;
}
.price > span {
    font-size: 0.7em;
}
.data-w-box {
    width: 100%;
    background-color: #f8f8f8;
    padding: 7.5em 0em;
    box-sizing: border-box;
}
.data-count-box {
    width: 91.25em;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.count-item-box {
    width: 16em;
    height: 10.875em;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1.875em;
    box-sizing: border-box;
    .count-left {
        img {
            width: 4.375em;
            height: 4.375em;
        }
    }
    .count-right {
        flex: 1;
        text-align: right;
        .count-num {
            font-size: 1.5em;
            color: #0073fe;
            font-weight: bold;
            span {
                font-size: 0.6em;
                margin-right: 0.3em;
            }
        }
        .count-title {
            font-size: 1em;
            color: #666666;
            margin-top: 1em;
        }
    }
}
.data-chart-box {
    width: 91.25em;
    margin: 0 auto;
    margin-top: 2.5em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.chart-left-box {
    width: 100%;
    //height: 32.5em;
    background-color: #ffffff;
    padding: 1.25em;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.order-change{
    position: absolute;
    top: 1em;
    right:2em;
    z-index: 99;
}
.orderchart-line {
    width: 2em;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.12);
    margin: 1em 0;
}
.chart-right-box {
    width: 100%;
    height: 34em;
    background-color: #ffffff;
    padding: 3.125em;
    box-sizing: border-box;
    overflow: hidden;
    margin-top: 2.5em;
}
.table-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.table-header-box {
    width: 100%;
    height: 3.75em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 115, 254, 0.12);
    .header-item {
        flex: 1;
        text-align: center;
        font-size: 1em;
        color: #333333;
        line-height: 1.5em;
        font-weight: bold;
    }
}
.tr-w-box {
    width: 100%;
    height: 26.5em;
    overflow: hidden;
    .tr-item-box:nth-child(even) {
        background-color: #f8f8f8;
    }
}
.tr-item-box {
    width: 100%;
    min-height: 3.75em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .td-item {
        flex: 1;
        text-align: center;
        font-size: 1em;
        color: #666666;
        line-height: 1.5em;
    }
}
.swiper-item-box {
    width: 100%;
}
.plmSwiper {
    height: 33em;
    :deep(.el-carousel__arrow) {
        display: none;
    }
}
.last-box{
    justify-content: space-between; 
    margin-bottom: 300px
}
#orderChart01{
    width: 100%; 
    height: 20em
}
#orderChart02{
    width: 100%; 
    height: 20em
}


@media screen and (max-width: 850px) {
    .type-title-item-box{
        width: 90%;
        margin: 0 auto;
        margin-top: 8.75em;
    }
    .data-count-box{
        width: 100%;
        display: block;
        flex-wrap: nowrap;
        .count-item-box{
            width: 46%;
            display: inline-flex;
            margin: 2%;
        }
        
    }
    .data-chart-box{
        width: 100%;
        flex-direction: column;
    }
    .chart-left-box{
        width: 100%;
        flex-direction: column;
    }
    .chart-right-box{
        width: 100%;
    }
    .content-box{
        width:100%;
        flex-direction: column;
        margin-top: 4em;
    }
    .main-box{
        width: 100%;
    }
    .product-item-box{
        width:90%;
        margin-bottom: 14em;
    }
    .ct-item-box{
        width:90%;
        height: auto;
        flex-direction: column;
        margin: 0 auto;
    }
    .intellectual-box{
        margin-bottom: 20px;
    }
    .content-item-box{
        width:90%;
        margin-bottom: 20px;
    }
    .content-left-box{
        margin-right: 0;
    }
    .plmSwiper{
        height: auto;
    }
    .content-right-box{
        padding: 0 2em;
        box-sizing: border-box;
    }
    .last-box{
        margin-bottom: 5em;
    }
    .chart-left-box{
        height: auto;
    }
    #orderChart01{
        width: 100%; 
        height: 20em;
    }
    #orderChart02{
        width: 100%; 
        height: 20em;
    }
}
</style>
